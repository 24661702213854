import axios from "axios";
import log from "../Utils/log";
import mediator from "libs/mediator";

const inputTokenSelector = 'input[name=authenticity_token]';

export default (function() {
  function setCSRFToken(token: string) {
    window.csrf_token = token;
    const inputs = document.querySelectorAll(inputTokenSelector) as NodeListOf<HTMLInputElement>;
    inputs.forEach((item: HTMLInputElement) => {
      item.value = token;
    });
  }

  function handleFormFocus(event: Event) {
    const target = event.target as HTMLFormElement;
    if (!target.form) {
      return;
    }

    const form = target.form;
    if (form.dataset.isUpdatedCsrf) {
      return;
    }

    if (!form.querySelector(inputTokenSelector)) {
      return;
    }

    axios
      .get(window.rootDir + '/tracking/csrf')
      .then(function(response) {
        setCSRFToken(response.data.csrf_token);
        form.dataset.isUpdatedCsrf = true;
      })
      .catch(err => {
        console.error(err.response);
      });
  }

  function run() {
    init();
    mediator.on("modal:show", init);
  }

  const ensureCSRFToken = new Promise((resolve, reject) => {
    if(window.embed) {
      resolve("ok");
      return true;
    }

    if (!window.csrf_token) {
      return axios
        .get(window.rootDir + '/tracking/csrf', {
          headers: {'X-Requested-With': 'XMLHttpRequest'}
        })
        .then(response => {
          setCSRFToken(response.data.csrf_token);
          resolve("Ok")
        })
        .catch(err => {
          console.error(err.response);
          reject(err);
        });
    }
  });

  function init() {
    log("CSRFManager init");
    const forms = document.querySelectorAll("form");

    forms.forEach((form) => {
      form.addEventListener('focus', handleFormFocus, true);
    });
  }

  return {
    run: run,
    ensureCSRFToken: ensureCSRFToken
  };
})();
